.socialMedia {
  color: #0fc0ab;
}

.businessCommunication {
  color: #17b8e6;
}

.signUpBtn {
  background: #1e2024 !important;
}

button {
  width: 100%;
}

.heading {
  font-size: calc(1em + 1vw);
  font-weight: 700;
}

@media (max-width: 767px) {
  .miniLogoWidth {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .miniLogoWidth {
    width: 25% !important;
  }

  button {
    width: 75% !important;
  }
}

@media (min-width: 1024px) {
  button {
    width: 50% !important;
  }
}
