.email {
    border-radius: 30px !important;
    border: none;
    outline: none;
}

.main {
    padding: 15% 10%;
}

.box {
    border-radius: 15px;
}

.horizontalLine {
    height: 1px;
    background-color: #ffff;
    margin: 10px 10px;
    width: 30px;
}

.codeExpiresText {
    font-size: calc(1em + 0.2vw);
}
