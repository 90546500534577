.container-main {
  position: fixed;
  overflow-y: scroll;
  height: 100%;
  display: flex;
}

.container-main > .row {
  margin: 0 auto;
  width: 100%;
}

.bg-primary-color {
  background-color: #1f1f1f;
}

.bg-color-black {
  background-color: #0b0b0b;
}

.bg-color-grey {
  background-color: #2b2b2b !important;
}

.bg-secondary-color {
  background-color: #0fc0ab;
}

.link {
  color: #5fc0ff;
  text-decoration: underline;
}

.link:hover {
  color: #1685cc !important;
  text-decoration: underline;
}

.active-link {
  color: #0a58ca !important;
  text-decoration: underline;
}

.white-space-nowrap {
  white-space: nowrap;
}

.border-none {
  border: none !important;
}

input[type="text"],
input[type="password"],
textarea {
  background-color: #2b2b2b !important;
  color: #f8f8f8 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

/* .react-tel-input {
	border-radius: 30px !important;
} */

.react-tel-input .form-control {
  height: 100% !important;
  width: 100% !important;
  border-radius: 30px !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background-color: #2B2B2B !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  margin-top: 0.5rem !important;
}

.flag-dropdown {
  border-radius: 30px !important;
  background-color: #2B2B2B !important;
  border: none !important;
  outline: none !important;
  position: absolute !important;
  top: 15px !important;
  left: 8px;
}

/* .flag-dropdown:hover {
  border-radius: 30px !important;
  background-color: #1A1A1A !important;
  border: none !important;
  outline: none !important;
} */

.country-list {
  background-color: #1a1a1a !important;
}

.heading {
  font-size: calc(1em + 0.6vw);
}

.heading2 {
  font-size: calc(1em + 0.2vw);
}

.margin-auto {
  margin: auto !important;
}

.text-align-center {
  text-align: center;
}

.w-20 {
  width: 20% !important;
}

.text-secondary-color {
  color: #0fc0ab;
}

.br-15 {
  border-radius: 15px;
}

.primary-heading {
  font-size: calc(1em + 0.6vw);
}

.secondary-heading {
  font-size: calc(1em + 0.4vw);
}

.hookzoffice-card-logo {
  width: calc(2em + 9vw);
}

.global-text-color {
  color: #f8f8f8;
}

.input-field {
  border-radius: 30px !important;
  border: none;
  outline: none;
}

.btn {
  background: linear-gradient(to right, #17a3b5, #4bab64);
  border-radius: 30px !important;
  border: none !important;
}

.mobile-input .react-tel-input .country-list .country.highlight,
.mobile-input .react-tel-input .country-list .country:hover {
  background: linear-gradient(to right, #17a3b5, #4bab64);
}

.mobile-input .react-tel-input .country-list .search {
  background: linear-gradient(to right, #17a3b5, #4bab64);
  padding: 6px 6px 6px 10px;
  border-radius: 10px;
}

.mobile-input .react-tel-input .country-list .search-box {
  width: 90%;
}

.password-open-close-eye {
  width: 1rem;
}

.error-color {
  color: #ff5757;
}

.padding-1px {
  padding: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 767px) {
  .btn {
    width: 80% !important;
  }
  .input-width {
    width: 80% !important;
  }
  .global-font-size {
    font-size: 0.8rem;
  }
  .right-section {
    width: 100% !important;
  }
  .hookzoffice-card-logo {
    width: calc(4em + 11vw);
  }
}

@media (min-width: 768px) {
  .width-50 {
    width: 50% !important;
  }
  .input-width {
    width: 100% !important;
  }
  .global-font-size {
    font-size: 1rem;
  }
  .hookzoffice-card-logo {
    width: calc(2em + 11vw);
  }
}

@media (min-width: 1440px) {
  .hookzoffice-card-logo {
    width: calc(2em + 6vw);
  }

  .right-section-box {
    margin: 0 2rem !important;
  }
}

@media (min-width: 1800px) {
  .right-section-box {
    margin: 0 4rem !important;
  }
}