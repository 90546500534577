.email {
    border-radius: 30px !important;
    border: none;
    outline: none;
}

input {
    border: none;
    outline: none;
}

.input-parent-div {
    border-radius: 30px !important;
}

.input-wrapper {
    border-radius: 30px;
    background-color: #2B2B2B;
}

.box {
    border-radius: 15px;
    height: auto;
}

.camera {
    bottom: -8px;
    left: 68px;
}

.back {
    left: 10%;
}

.mandotory {
    color: #FE2811;
}

.emails-container {
    max-height: 200px;
    overflow-y: auto;
}

.emails-container::-webkit-scrollbar{
    display: none;
}
