.email {
  border-radius: 30px !important;
  border: none;
  outline: none;
}

.main {
  /* height: 100vh; */
  padding: 15% 10%;
}

.mt-8 {
  margin-top: 7rem;
}

.box {
  border-radius: 15px;
}
