.email {
    border-radius: 30px !important;
    border: none;
    outline: none;
}

.box {
    border-radius: 15px;
}

.sign-up-for {
    white-space: nowrap !important;
}
