.workstations {
  max-height: 400px;
  overflow-y: auto;
}

.workstations::-webkit-scrollbar{
  display: none;
}

.logo {
  /* width: 80px;
  height: 80px; */
  border-radius: 15%;
  object-fit: cover;
}

.btn-text-font-size {
  font-size: 18px !important;
}

.img-alt {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 80px;
  height: 80px; */
  border-radius: 15%;
  font-size: 1.7rem;
  font-weight: bold;
}

.workstation-name, .workstation-website {
  white-space: nowrap;      
  overflow: hidden;         
  text-overflow: ellipsis;   
}

.arrow {
  width: 1rem;
}

.fetching-workstations {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 300px) and (max-width: 425px) {
  .workstation-name, .workstation-website {
    width: 13rem;
  }

  .workstation-logo {
    width: 2rem !important;
    height: 2rem !important;
  }

  .workstation-name {
    font-size: calc(1em + 0.2vw);
  }
  
  .workstation-website {
    font-size: calc(0.8em + 0.1vw);
  }

  .main-content {
    padding: 0 0.5rem !important;
  }
}

@media (min-width: 426px) and (max-width: 767px) {
  .workstation-logo {
    width: 2rem !important;
    height: 2rem !important;
  }

  .workstation-name {
    font-size: calc(1em + 0.3vw);
  }
  
  .workstation-website {
    font-size: calc(0.8em + 0.1vw);
  }

  .workstation-name, .workstation-website {
    width: 6rem;
  }

  .main-content {
    padding: 0 0.5rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .workstation-logo {
    width: 3rem !important;
    height: 3rem !important;
  }

  .main-content {
    padding: 0 2rem !important;
  }
 
  .workstation-website {
    font-size: calc(0.4em + 0.4vw);
  }

  .workstation-name, .workstation-website {
    width: 14rem;
  }

}

@media (min-width: 1025px) and (max-width: 1440px) {
  .workstation-logo {
    width: 4rem !important;
    height: 4rem !important;
  }

  .main-content {
    padding: 0 5rem !important;
  }

  .workstation-name, .workstation-website {
    width: 18rem;
  }

}

@media (min-width: 1441px) and (max-width: 1700px) {
  .workstation-logo {
    width: 4.5rem !important;
    height: 4.5rem !important;
  }

  .main-content {
    padding: 0 8rem !important;
  }

  .workstation-name, .workstation-website {
    width: 20rem;
  }

}

@media (min-width: 1701px) and (max-width: 2000px) {
  .workstation-logo {
    width: 4.5rem !important;
    height: 4.5rem !important;
  }

  .main-content {
    padding: 0 10rem !important;
  }

  .workstation-name, .workstation-website {
    width: 21rem;
  }

}

@media (min-width: 2001px) {
  .workstation-logo {
    width: 5rem !important;
    height: 5rem !important;
  }

  .main-content {
    padding: 0 15rem !important;
  }

  .workstation-name, .workstation-website {
    width: 25rem;
  }

  .arrow {
    width: 1.5rem;
  }
}