.email {
  border-radius: 30px !important;
  border: none;
  outline: none;
}

.br-15 {
  border-radius: 15px;
}

.plans-container {
  max-height: 400px;
  overflow-y: auto;
}

.plans-container::-webkit-scrollbar {
  display: none;
}

@media (min-width: 2000px) {
  .custom-col {
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}
