.email {
    border-radius: 30px !important;
    border: none;
    outline: none;
}

.main {
    /* height: 100vh; */
    padding: 7% 15%;
}

.box {
    border-radius: 15px;
    height: auto;
}

.br-15 {
    border-radius: 15px;
}

.workstations {
    height: 400px;
}

.logo {
    width: 80px !important;
    height: 80px;
    border-radius: 15%;
    object-fit: cover;
}

.img-alt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px !important;
    height: 80px;
    border-radius: 15%;
    font-size: 1.7rem;
    font-weight: bold;
}
