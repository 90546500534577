.email {
  border-radius: 30px !important;
  border: none;
  outline: none;
}

.main {
  /* height: 100vh; */
  padding: 15% 10%;
}

.box {
  border-radius: 15px;
  height: auto;
}
