.logout {
  color: #5fc0ff;
  /* position: absolute;
  top: 1rem;
  right: 1rem; */
  font-size: calc(1em + 0.3vw);
}

.logout:hover {
  color: #1685cc !important;
}
