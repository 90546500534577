.email, .bio {
    border-radius: 30px !important;
    border: none;
    outline: none;
    resize: none;
}

.main {
    /* height: 100vh; */
    padding: 15% 10%;
}

.box {
    border-radius: 15px;
}

.camera {
    bottom: -8px;
    left: 68px;
}

.mandotory {
    color: #FE2811;
}

.avatar-container {
    width: 100px; /* Replace with your circular avatar width */
    height: 100px;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Scale the image while maintaining aspect ratio */
    border-radius: 50%;
}

.deselect-logo {
    position: absolute;
    right: 0;
}